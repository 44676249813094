<template>
  <div v-if="getAlarmas">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <b-card
        no-body
        class="business-card"
      >
        <b-card-body>
          <b-card-text>Alarmas por defecto al crear un Producto.
            <br>Cada alarma
            hace referencia a la cantidad de stock desde el cual, se accionará cada color
            en la columna "Stock" de la "Lista de Productos".<br>
            Al momento de crear un producto, cada alarma se podrá personalizar
            a lo que el producto requiera.
            </b-card-text>
          <alarmasForm
            alarmasSubmit="Editar Alarmas"
            :alarmas.sync="alarmas"
            :tipoUsuario="getUser.tipoUsuario"
            @processAlarmas="editarAlarmas"
          />
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BCard, BCardBody, BCardText, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const alarmasForm = () => import('@/layouts/components/Navbar/Configuraciones/Alarmas/AlarmasForm.vue')

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BOverlay,
    alarmasForm,
  },
  data() {
    return {
      spinner: false,
      alarmas: {},
    }
  },
  computed: {
    // ...mapState('alarmas', ['alarmas']),
    ...mapGetters({
      getAlarmas: 'alarmas/getAlarmas',
      getUser: 'auth/user',
    }),
  },
  mounted() {
    this.fetchAlarmas().then(() => {
      this.alarmas = this.getAlarmas
    })
  },
  methods: {
    ...mapActions({
      fetchAlarmas: 'alarmas/fetchAlarmas',
      updateAlarmas: 'alarmas/updateAlarmas',
    }),
    editarAlarmas(alarma) {
      this.spinner = true
      this.updateAlarmas(alarma).then(() => {
        const errorAlarmas = store.state.alarmas
        if (!errorAlarmas.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Configuración de alarmas editada con éxito.!',
              text: '👍 La configuración de alarmas fue editada con éxito.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'home',
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
